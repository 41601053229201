import { Button, Card, Container, Stack, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocales from '#/lib/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

export default function WelcomeCard() {
  const { translate } = useLocales();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleNext = () => {
    navigate(`/vorsorge/schritt=2`, {
      state: {
        redirectLink: searchParams.get('redirectLink'),
        step: 2,
      },
    });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginY: 12,
      }}
    >
      <Card
        sx={{
          p: 3,
          mt: 4,
        }}
      >
        <Typography variant="subtitle1" textAlign="center" sx={{ marginY: 4 }}>
          {String(translate('pensionProvision.onboarding.welcomeCard.title'))}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Iconify icon="eva:checkmark-circle-2-fill" color="#54D62C" />
          <Typography variant="body1">
            {String(
              translate(
                'pensionProvision.onboarding.welcomeCard.successMessage'
              )
            )}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Iconify icon="eva:checkmark-circle-2-fill" color="#54D62C" />
          <Typography>
            {String(
              translate('pensionProvision.onboarding.welcomeCard.emailMessage')
            )}
          </Typography>
        </Stack>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ marginTop: 3 }}
        >
          {String(
            translate('pensionProvision.onboarding.welcomeCard.nextStep')
          )}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
          1.{' '}
          <Typography>
            {String(
              translate('pensionProvision.onboarding.welcomeCard.stepOne')
            )}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          2.{' '}
          <Typography>
            {String(
              translate('pensionProvision.onboarding.welcomeCard.stepTwo')
            )}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          3.{' '}
          <Typography>
            {String(
              translate('pensionProvision.onboarding.welcomeCard.stepThree')
            )}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            sx={{ marginTop: 4 }}
          >
            <Typography>{String(translate('global.continue'))}</Typography>
          </Button>
        </Stack>
      </Card>
    </Container>
  );
}
